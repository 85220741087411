import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { displayError } from "sites-common/utils/customErrors";
import {
  fetchSrcmAPI,
  fetchSrcmAPIDecompose,
} from "sites-common/service-wrappers/profile-api";
import { selectorJson as S, selectorSrcmToken } from "./state/selectors";
import { fetchSrcmApiIfNeeded as A } from "./state/actions";
import {
  fetchSrcmApiStoreIfNeeded,
  fetchSrcmApiStorePromise,
  initSrcmCollection,
} from "./state/actions_srcm";

export const useSrcmApi = (key, apiParams) => {
  const srcmtoken = useSelector(selectorSrcmToken);
  const dispatch = useDispatch();
  const { isLoaded, isFetching, data, errorMessage } = useSelector((state) =>
    S(state, key)
  );

  const refresh = useCallback(
    () => dispatch(A(key, apiParams, true)),
    [dispatch, key, apiParams]
  );
  const updateParams = useCallback(
    (params) => dispatch(A(key, params, true)),
    [dispatch, key]
  );

  useEffect(() => {
    if (!!srcmtoken.token && !isFetching && !isLoaded && !!apiParams) {
      dispatch(A(key, apiParams));
    }
  }, [srcmtoken.token, isFetching, isLoaded, dispatch, key, apiParams]);

  return {
    isLoaded,
    isFetching,
    data,
    errorMessage,
    refresh,
    updateParams,
  };
};

export const selectorSrcmApi = S;
export const actionSrcmApi = A;

export const useSrcmApiStore = (collection, apiParams) => {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);

  return {
    fetcher: (p, f = false) => fetchSrcmApiStoreIfNeeded(collection, p, f),
    type: "srcmstore",
    ops: {
      /* dispatchers */
      initCollection: (table, apiParams1) =>
        dispatch(initSrcmCollection(table, apiParams1)),
      triggerSnapshot: (table, p = { page: 1, page_size: 20 }, f = false) => {
        if (p.page) {
          dispatch(fetchSrcmApiStoreIfNeeded(table, p, f));
        }
      },

      /* Promises */
      get: (collection1, docId, metaParams = {}) =>
        fetchSrcmApiStorePromise(
          state,
          dispatch,
          collection1,
          docId,
          "GET",
          metaParams
        ),
      exists: (collection1, docId, metaParams = {}) =>
        fetchSrcmApiStorePromise(
          state,
          dispatch,
          collection1,
          docId,
          "GET",
          metaParams
        )
          .then((r) => r && r.id === docId)
          .catch(() => false),
      delete: (collection1, docId, metaParams = {}) => {
        displayError(collection1, docId, metaParams);
        Promise.reject(Error("Delete Not Yet Supported"));
      },
      setMerge: (collection1, docId, methodParams, metaParams = {}) =>
        fetchSrcmApiStorePromise(
          state,
          dispatch,
          collection1,
          docId,
          docId === 0 ? "POST" : "PATCH",
          methodParams,
          metaParams,
          true
        ),
      setOverwrite: (collection1, docId, methodParams, metaParams = {}) =>
        fetchSrcmApiStorePromise(
          state,
          dispatch,
          collection1,
          docId,
          docId === 0 ? "POST" : "PUT",
          methodParams,
          metaParams,
          true
        ),
      getSnapshot: () => Promise.reject(Error("GetSnapshot Not Implemented")),
    },
    init: () => {
      dispatch(initSrcmCollection(collection, apiParams));
      dispatch(
        fetchSrcmApiStoreIfNeeded(collection, { page: 1, page_size: 200 })
      );
    },
  };
};

export const useFetchSrcmApi = () => {
  const srcmtoken = useSelector(selectorSrcmToken);
  const x__fetchSrcmAPI = useCallback(
    ({
      api,
      method = "GET",
      headers = {},
      data = {},
      forceAuth = false,
      methodParams = {},
      client = "baseClient",
    }) =>
      fetchSrcmAPI(
        api,
        srcmtoken.token,
        method,
        headers,
        data,
        forceAuth,
        methodParams,
        client
      ),
    [srcmtoken]
  );
  const x__fetchParams = useCallback(
    ({
      api,
      method = "GET",
      headers = {},
      data = {},
      forceAuth = false,
      methodParams = {},
      client,
    }) =>
      fetchSrcmAPIDecompose(
        api,
        srcmtoken.token,
        method,
        headers,
        data,
        forceAuth,
        methodParams,
        client
      )[1],
    [srcmtoken]
  );
  return {
    fetchSrcmApi: x__fetchSrcmAPI,
    fetchParams: x__fetchParams,
  };
};
