/** @jsx jsx */
import { jsx, Text, Badge } from "theme-ui";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import { ActionButton, DefaultButton } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { convertToB64Str } from "sites-common/utils/encoded";

import SrcmForm from "gatsby-plugin-hfn-profile/components/SrcmForm";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import DefaultLayout from "../../layouts/dlw-mobile";

const NewProject = ({ onCreation }) => {
  const one = "one";

  const srcmApiForForm = SrcmForm.useSrcmApiForForm(
    {
      api: "/api/v3/teams/",
    },
    null,
    {
      fields: {
        name: {
          type: "text",
          label: "Team Name",
        },
      },
      transformFormDataForSaving: (r) => r,
    },
    one
  );

  const { apiDataVersion, apiData, formData } = srcmApiForForm;

  const success = apiDataVersion > 1 && formData?.id;

  useEffect(() => {
    if (success) {
      onCreation(apiData.id);
    }
  }, [success, onCreation, apiData]);

  if (success) {
    return (
      <div>
        <Badge variant="info"> Successfully Created {formData.name}</Badge>
      </div>
    );
  }

  return (
    <div key={formData ? formData.id : "A"}>
      <SrcmForm srcmApiForForm={srcmApiForForm} callApiMethod="POST">
        <div sx={{ textAlign: "center", my: 2 }}>
          <Text variant="title">Create New Team</Text>
        </div>

        <div sx={{ textAlign: "left", mb: 3 }}>
          <SrcmForm.SubForm title="" name={one} allwaysOpen saveBtnTxt="Submit">
            <SrcmForm.InputField name="name" section={one} />
          </SrcmForm.SubForm>
        </div>
      </SrcmForm>
    </div>
  );
};
NewProject.propTypes = {
  onCreation: PropTypes.func.isRequired,
};
const colDefns = [
  [
    "Name",
    (p) => (
      <ActionButton
        iconProps={{ iconName: "Forward" }}
        text={p.name}
        href={`/functionary-hub/team/?t=${convertToB64Str(JSON.stringify(p))}`}
      />
    ),
  ],
];

const AllProjects = () => {
  const [vers, setVers] = useState(0);
  const { srcmProfile } = useAuth();

  const [showNew, { toggle: toggleNew }] = useMyBoolean(false);
  const { showAlert } = useAlert();

  const { fetchSrcmApi } = useFetchSrcmApi();
  const onNewCreation = useCallback(
    (projectId) => {
      const memberType = "owners";
      const methodParams = {
        partner: srcmProfile.id,
      };
      fetchSrcmApi({
        api: `/api/v3/teams/${projectId}/${memberType}/`,
        method: "POST",
        methodParams,
      })
        .then(() => {
          setVers((v) => v + 1);
          toggleNew();
        })
        .catch((e) => {
          showAlert(e.toString());
        });
    },
    [toggleNew, setVers, fetchSrcmApi, showAlert, srcmProfile]
  );

  return (
    <DefaultLayout sx={{ p: 2 }}>
      <div sx={{ mb: 2 }}>
        <ActionButton
          text="Functionary Hub"
          iconProps={{ iconName: "Back" }}
          href="/functionary-hub/"
        />
      </div>

      <Text variant="header">All Teams</Text>

      <SrcmSearchPage
        reduxKey={`me-search-teams${vers}`}
        apiParams={{
          api: `/api/v3/teams/`,
        }}
        noSearchDisplay
        colDefns={colDefns}
      />

      <div>
        {!showNew && (
          <DefaultButton
            iconProps={{ iconName: "Add" }}
            text="Create New Team"
            onClick={toggleNew}
          />
        )}
        {showNew && <NewProject onCreation={onNewCreation} />}
      </div>
    </DefaultLayout>
  );
};

export default AllProjects;
